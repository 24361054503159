@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      @apply text-6xl;
      @apply font-bold;
    }
    h2 {
      @apply text-5xl;
      @apply font-bold;
    }
    h3 {
      @apply text-3xl;
      @apply font-bold;
    } 
}

* {
  margin:0;
  font-family: 'Poppins', sans-serif;
}

.card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  overflow: hidden;
}

.bg{
  position: absolute;
  transform: translate(6rem, -4rem);
  background: linear-gradient(to top right, #ff6666 25%, #9999ff 100%);
}

.product {
  margin: 20px 10px;
  border: 1px solid rgb(243, 242, 242);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.p-img {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.product:hover .p-img {
  transform: scale(1.1);
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
}

.ityped-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media screen and (max-width: 767px) {
  .bg {
    display: none;
  }

  .card {
    margin-top: 4rem;
    height: 30vh;
  }
}